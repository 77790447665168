.link {
  display: flex;
  padding: 0.6rem 0;
  align-items: center;
}

.link svg {
  margin-right: 0.7rem;
}

.container {
  color: var(--text-color-alt);
  text-transform: uppercase;
}

.container > a {
  width: 200px;
  height: 55px;
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 2.5rem;
}

.container > a svg {
  width: 200px;
  height: 55px;
}

.container > a svg g {
  fill: #fff;
}

.categoryName {
  color: var(--color-primary);
  font-weight: bold;
  font-size: 0.7rem;
}

.items {
  list-style: none;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.networks {
  margin-top: -0.6rem;
}

.networks a {
  margin-right: 1.5rem;
}
