/* stylelint-disable selector-pseudo-class-no-unknown */
.container {
  height: var(--header-height);
  width: 100vw;
  position: fixed;
  z-index: 10;
  transition: var(--transition-default);
  background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 150%);
}

.innerContainer {
  margin: auto;
  justify-content: space-between;
  display: flex;
  max-width: var(--desktop-max-width);
}

:global(.notTop) .container,
:global(.plainHeader) .container,
.container:hover,
.menu .items {
  background: rgb(0 0 0 / 90%);
  backdrop-filter: blur(2px);
  transition: var(--transition-default);
}

.logo {
  display: flex;
  align-items: center;
  padding-top: 0.2rem;
  justify-content: flex-start;
  transition: var(--transition-default);
}

.logo svg {
  height: calc(var(--header-height) * 0.6);
  width: 180px;
  left: 0;
}

.logo g {
  fill: #fff;
  transition: var(--transition-default);
}

.logo:hover {
  transition: var(--transition-default);
}

.logo:hover g {
  fill: var(--color-primary);
  transition: var(--transition-default);
}

.link {
  display: flex;
  padding: 0.6rem 0;
  align-items: center;
  transition: var(--transition-default);
}

.link path {
  transition: var(--transition-default);
}

.link:hover {
  color: var(--color-primary);
  transition: var(--transition-default);
}

.lenseBtn > a > svg path {
  fill: #fff;
}

.link:hover path {
  fill: var(--color-primary);
  transition: var(--transition-default);
}

.link svg {
  margin-right: 0.7rem;
}

.lenseBtn {
  display: flex;
  flex-shrink: 0;
  height: var(--header-height);
  width: var(--header-height);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lenseBtn > a > svg {
  --size: calc(var(--header-height) * 0.4);

  width: var(--size);
  height: var(--size);
}

.lenseBtn:hover > a > svg path {
  fill: var(--color-primary);
  transition: var(--transition-default);
}

.menu .categoryName,
.lenseBtn > a > svg,
.logo > a > svg {
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 90%));
}

.menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.menu .category {
  height: 100%;
  display: flex;
  align-items: center;
  transition: var(--transition-default);
}

.menu .categoryName {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  padding: 0 1rem;
  line-height: var(--header-height);
  display: block;
}

.menu .items {
  margin: 0;
  padding: 0.5rem 2rem 0.5rem 1rem;
  list-style: none;
  display: none;
  border: 1px solid #000;
  color: #fff;
  position: fixed;
  top: var(--header-height);
  min-width: 10rem;
  transition: var(--transition-default);
}

.menu .category:hover {
  transition: var(--transition-default);
}

.menu .category:hover .categoryName {
  color: var(--color-primary);
}

.menu .category:hover .items {
  display: block;
  transition: var(--transition-default);
}
