.field {
  margin: var(--horizontal-spacing-default);
  background: #eee;
  display: flex;
  align-items: center;
}

.field svg,
.field path {
  fill: #444;
}

.field svg {
  margin: 0.4rem;
}

.input {
  border: none;
  background: transparent;
  flex-grow: 1;
  padding: var(--horizontal-spacing-default) 0;
  font-size: 0.9rem;
  color: #000;
}

.input:focus {
  outline: none;
}

.resetInput {
  display: flex;
  cursor: pointer;
}

.resetInput svg {
  margin-right: 0.6rem;
}

.resetInput svg,
.filter svg {
  height: 1.2rem;
  width: 1.2rem;
}

.filter {
  display: flex;
  background: #ccc;
  align-items: center;
  padding-left: var(--horizontal-spacing-default);
  font-size: 0.8rem;
}

.filter a {
  display: flex;
  cursor: pointer;
}
