.container404,
.container500 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container404 {
  backdrop-filter: grayscale(100%) contrast(0.5) brightness(1);
}

.container500 {
  backdrop-filter: grayscale(100%);
}

.box {
  width: 100vw;
  margin: auto;
  text-align: center;
  background: rgb(255 255 255 / 70%);
  padding: 2rem;
  color: #444;
  line-height: 1.5rem;
}

@media (width >= 768px) {
  .box {
    width: 50vw;
  }
}

.container500 .box {
  background: rgb(255 255 255 / 20%);
  color: #111;
}

.container500 path {
  fill: #111;
}

.box svg {
  width: 15rem;
  height: 10rem;
}

.buttons g,
.buttons path {
  fill: #888;
}

.buttons > * {
  margin: var(--horizontal-spacing-default);
}

.buttons svg {
  width: 1rem;
  height: 1rem;
}
