.button {
  --bg-color: #eee;
  --text-color: var(--color-primary-attenuate);

  border-radius: 999px;
  padding: 0.7rem 1.3rem;
  background: var(--bg-color);
  color: var(--text-color);
  display: inline-block;
  transition: var(--transition-default);
}

.button:hover {
  --text-color: var(--color-primary-attenuate);

  transition: var(--transition-default);
}

.primary {
  --bg-color: var(--color-primary);
  --text-color: #000;
}

.alternate {
  --bg-color: #fff;
  --text-color: #000;
}

.faded {
  --bg-color: none;
  --text-color: #000;
}

.content {
  display: flex;
  align-items: center;
}

.content svg {
  margin-left: -0.5rem;
  margin-right: 0.2rem;
}
