.list {
  list-style: none;
  margin: 0 var(--horizontal-spacing-default);
  padding: 0;
  display: flex;
  padding-bottom: 1rem;
  overflow: auto;
}

.item {
  background: #ccc;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-left: var(--horizontal-spacing-default);
  padding: 0.3rem var(--horizontal-spacing-default);
  white-space: nowrap;
}

.item:hover {
  cursor: pointer;
}

.item:first-of-type {
  margin-left: 0;
}

.item svg {
  margin-right: 0.3rem;
}

.count {
  padding: 0.2rem;
  background: rgb(255 255 255 / 50%);
  border-radius: 500px;
  font-weight: bold;
  font-size: 0.7rem;
  margin-left: var(--horizontal-spacing-default);
}
