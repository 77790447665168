.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-default);
  background: var(--bg-color-alt);
  font-size: 0.9rem;
}

.networks,
.links {
  margin-bottom: 1rem;
}

.networks {
  display: flex;
}

.networks a {
  padding: 0 0.8rem;
}

.links {
  display: flex;
  color: var(--text-color-alt);
}

.links a {
  padding: 0 0.5rem;
}

.copyright {
  color: var(--text-color-alt-attenuate);
}
