/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-pseudo-class-no-unknown */
.container {
  /* background: #000; */
  display: flex;
  height: var(--header-height);
  width: 100vw;

  /* border-bottom: 1px solid #222; */
  position: fixed;
  z-index: 10;
  transition: background 0.3s linear;
}

:global(.notTop) .container,
:global(.plainHeader) .container {
  background: rgb(0 0 0 / 90%);
  backdrop-filter: blur(2px);
  transition: background 0.3s linear;
}

.menu,
.lenseBtn {
  width: var(--header-height);
  height: var(--header-height);
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.menu,
.lenseBtn,
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo svg {
  display: flex;
  height: calc(var(--header-height) * 0.6);
  width: 100%;
}

.menu svg,
.lenseBtn > a svg {
  width: var(--icon-size);
  height: var(--icon-size);
}

.menu > a > svg,
.lenseBtn > a > svg,
.logo > a > svg {
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 90%));
}

.logo {
  flex-grow: 1;
}

.logo g,
.menu g,
.lenseBtn > a g {
  fill: #fff;
  transition: var(--transition-default);
}

.logo:hover g,
.menu:hover g,
.lenseBtn:hover > a g {
  fill: var(--color-primary);
  transition: var(--transition-default);
}

.logo:active g,
.menu:active g,
.lenseBtn:active > a g {
  fill: var(--color-primary);
}

.lenseBtn > a svg {
  margin-top: 3px;
}
