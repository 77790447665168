.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: auto;
}

.content {
  background: var(--body-bg-color);
  flex-grow: 1;
  position: relative;
}

.content > * {
  background: var(--bg-color);
  margin: auto;
}

@media (width >= 768px) {
  .content > * {
    max-width: var(--tablet-max-width);
  }
}

@media (width >= 1225px) {
  .content > * {
    max-width: var(--desktop-max-width);
  }
}

.withHeaderSizeMargin {
  margin-top: var(--header-height);
}
