.container {
  background-size: cover;
  background-position: center;
  position: relative;

  --gradient: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 50%) 150%);
}

.errorGradient {
  margin: 0;
  max-width: 100vw !important;
}

.highlightsGradient {
  height: 100%;
}

.spotlightGradient {
  --gradient: linear-gradient(180deg, rgb(0 0 0 / 0%) 40%, rgb(0 0 0 / 70%) 60%);

  margin-bottom: calc(var(--horizontal-spacing-default) * 2);
}

.tileGradient {
  --gradient: linear-gradient(180deg, rgb(0 0 0 / 0%) 55%, rgb(0 0 0 / 50%) 80%);

  aspect-ratio: 150/225;

  /* To Remove, used to clean cache */
  margin-right: 0;
}

.coverGradient,
.coverBlurGradient {
  --gradient: linear-gradient(180deg, rgb(0 0 0 / 0%) 75%, rgb(0 0 0 / 75%) 90%);
}

@media (width >= 768px) {
  .coverBlurGradient .gradient {
    backdrop-filter: blur(10px);
  }
}

.singleEntrySpotlightGradient {
  --gradient: linear-gradient(270deg, rgb(0 0 0 / 0%) 35%, rgb(0 0 0 / 45%) 70%);
}

.internalContainer {
  z-index: 2;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: var(--gradient);
}
