.loader {
  width: 5rem;
  height: 5rem;
  margin: 3rem auto;
}

.loader path {
  fill: #aaa;
}

.shortcut {
  color: #aaa;
  font-weight: bold;
  border: 2px solid #aaa;
  padding: 0.2rem 0.3rem;
  border-radius: 0.4rem;
  margin: 0 0.2rem;
}

.doSearch {
  margin-top: 25vh;
  display: none;
}

.doSearch,
.noResults {
  text-align: center;
}

.doSearch p {
  color: #aaa;
}

.noResults p {
  margin: 0;
}

.doSearch svg,
.noResults svg {
  width: 15rem;
  height: 10rem;
}

.noResults g {
  fill: #888;
}

.doSearch g {
  fill: #aaa;
}

@media (width >= 768px) {
  .doSearch {
    display: block;
  }
}
