.container {
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding-bottom: var(--horizontal-spacing-default);
}

.container > * {
  margin-bottom: var(--horizontal-spacing-default);
}
