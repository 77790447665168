.container {
  position: initial;
  top: 100vw;
  left: auto;
  transition: all 50ms linear;
}

.containerFixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw !important;
  display: flex;
  flex-direction: column;
  z-index: 1001; /* To pass over react menu burger icon */
  transition: all 50ms linear;
  background: var(--bg-color);
  cursor: initial;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  width: 100%;
}

.content > * {
  max-width: var(--desktop-max-width);
  flex-shrink: 0;
}

.containerFixed .content {
  padding-top: var(--header-height);
  max-width: 900px;
  height: 100vh;
}

.header {
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  height: var(--header-height);
  position: fixed;
  width: 100vw;
}

.title {
  flex-grow: 1;
  font-weight: normal;
  font-size: 1.2rem;
  padding-left: 0.8rem;
}

.closeButton {
  display: flex;
  width: var(--header-height);
  height: var(--header-height);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeButton svg {
  width: var(--icon-size);
  height: var(--icon-size);
}

.closeButton path {
  transition: var(--transition-default);
}

.closeButton:hover path {
  fill: var(--color-primary);
  transition: var(--transition-default);
}
