.container {
  color: var(--text-color);
  display: flex;
  padding-bottom: var(--horizontal-spacing-default);
  border-bottom: 1px solid var(--separator-color);
}

.picture {
  max-width: 150px;
  max-height: 150px;
  width: 25%;
  height: 25%;
  margin-right: 0.6rem;
  flex-shrink: 0;
}

.picture img,
.picture svg {
  width: 100%;
  height: 100%;
}

.description,
.subtitle,
.title {
  line-height: 1.2rem;
  font-size: 0.9rem;
  display: block;
}

.title {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
